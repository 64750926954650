import styled from 'styled-components'

import HighlightText from 'components/HighlightText'
import Box from 'components/Primitives/Box'
import Separator from 'components/Separator'
import createPriceString, {
    getEndDateAfterDays,
    getEndDateAfterMonths,
} from 'lib/createPriceString'
import type { Price } from 'lib/pricing/types'
import type { ThemedComponent } from 'theme'
import { trispace } from 'theme'
import { isAnnual } from 'types/stripeConsts'

type Props = {
    price: Price
    encryptedEmail?: string
}

export const PRICING_DETAIL_TITLE = 'Finimize Pro Membership'

const getWelcomeOfferText = (months: number | null, isAnnual: boolean) => {
    if (!months || isAnnual) return 'Welcome offer: '

    const monthText = months === 1 ? 'month' : 'months'
    return `${months} ${monthText} welcome offer: `
}

export const CheckoutDetail = ({ price }: Props) => {
    const { promotionApplied, interval, currency, currencySymbol, amount, trialDays = null } = price
    const {
        couponDurationInMonths = null,
        discountPct = null,
        discountAmount = null,
        couponRepetition,
    } = promotionApplied || {}

    const firstPaymentPrice = createPriceString({
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: discountPct,
        stripeDiscountAmount: discountAmount,
        hideCurrencyInfo: true,
    })

    const trialPaymentPrice = createPriceString({
        amount: 0,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: null,
        stripeDiscountAmount: null,
        hideCurrencyInfo: true,
    })

    const noDiscountPrice = createPriceString({
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: null,
        stripeDiscountAmount: null,
        hideCurrencyInfo: true,
    })

    const displayedDiscountedAmount = !!discountAmount
        ? createPriceString({
              amount: discountAmount ? discountAmount / 100 : 0,
              currency: currency,
              currencySymbol: currencySymbol,
              stripeDiscountPct: null,
              stripeDiscountAmount: null,
              hideCurrencyInfo: true,
          })
        : null

    const hasDiscount = !!discountPct || !!discountAmount
    const hasTrial = !!trialDays
    const isAnnualPlan = !!interval && isAnnual(interval.toLowerCase())
    const discount = hasDiscount && (discountPct ? `${discountPct}%` : displayedDiscountedAmount)

    const trialEndDate = getEndDateAfterDays(trialDays)
    const promotionEndDate = getEndDateAfterMonths(couponDurationInMonths)

    const totalDueToday = hasTrial ? trialPaymentPrice : firstPaymentPrice
    const foreverDiscount = couponRepetition === 'FOREVER'

    return (
        <Container>
            <>
                <Header>Order summary</Header>

                <Text>{PRICING_DETAIL_TITLE}</Text>
                {hasDiscount && (
                    <PriceLineContainer>
                        <PromotionText>
                            {getWelcomeOfferText(couponDurationInMonths, isAnnualPlan)}
                            <span data-cy="discount-amount">{discount} off</span>
                        </PromotionText>
                        <Box display="flex" flexDirection="row" gridGap="1rem">
                            <PriceText $isDiscounted={hasDiscount}>{noDiscountPrice}</PriceText>
                            <PriceText>{firstPaymentPrice}</PriceText>
                        </Box>
                    </PriceLineContainer>
                )}

                <Separator />

                {hasTrial && (
                    <>
                        <PromotionText>Free {trialDays} day trial</PromotionText>
                        {hasDiscount && (
                            <PriceLineContainer>
                                <SubText>
                                    Your card will be charged{' '}
                                    {trialEndDate ? `on ${trialEndDate}` : 'today'}
                                </SubText>
                                <SubPriceText>{firstPaymentPrice}</SubPriceText>
                            </PriceLineContainer>
                        )}
                        <Separator />
                    </>
                )}

                <PriceLineContainer>
                    <SubText>
                        Total due {isAnnualPlan ? 'annually' : 'monthly'} from{' '}
                        {promotionEndDate || trialEndDate || 'today'}
                    </SubText>
                    {foreverDiscount ? (
                        <SubPriceText>{firstPaymentPrice}</SubPriceText>
                    ) : (
                        <SubPriceText>{noDiscountPrice}</SubPriceText>
                    )}
                </PriceLineContainer>

                {!isAnnualPlan && <SubText>You can cancel anytime</SubText>}

                <Separator />
                <PriceLineContainer>
                    <Text>Total due today:</Text>
                    <AmountDueText data-cy="first-payment-amount">{totalDueToday}</AmountDueText>
                </PriceLineContainer>
            </>
        </Container>
    )
}

const Container = styled.div`
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: ${({ theme }) => theme.palette.neutral[50]};
`

const Header = styled(HighlightText)`
    font-size: ${({ theme }) => theme.fontSize.XL};
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    color: ${({ theme }) => theme.palette.neutral[999]};
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 1rem;
    width: fit-content;
`

const AmountDueText = styled.p`
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    font-size: ${({ theme }) => theme.fontSize.XL};
    color: ${({ theme }) => theme.palette.neutral[999]};
    letter-spacing: 0.05em;
    padding: 0.5rem 0;
`

const PriceLineContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const Text = styled.p`
    font-family: ${trispace.style.fontFamily};
    font-size: ${({ theme }) => theme.fontSize.S};
    color: ${({ theme }) => theme.palette.neutral[999]};
    padding: 0.5rem 0;
`

const SubText = styled(Text)`
    color: ${({ theme }) => theme.palette.neutral[600]};
`

const PriceText = styled(Text)<ThemedComponent<{ $isDiscounted?: boolean }>>`
    text-decoration: ${({ $isDiscounted }) => $isDiscounted && 'line-through'};
    color: ${({ $isDiscounted, theme }) => $isDiscounted && theme.palette.neutral[600]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const SubPriceText = styled(PriceText)`
    color: ${({ theme }) => theme.palette.neutral[600]};
`

const PromotionText = styled(Text)`
    color: ${({ theme }) => theme.palette.accent[500]};
`

export default CheckoutDetail
