import styled from 'styled-components'

import type { SeoProps } from './SeoWrapper'
import SeoWrapper from './SeoWrapper'

import Hide from 'components/Hide'
import Box from 'components/Primitives/Box'
import CheckoutDetail from 'containers/Checkout/CheckoutDetail/CheckoutDetail'
import FullWidthBackground from 'containers/FullWidthBackground'
import MaxWidthContainer from 'containers/MaxWidthContainer'
import type { Price } from 'lib/pricing/types'
import CheckoutNavBar from 'modules/Checkout/CheckoutNavBar'
import type { ThemedComponent } from 'theme/Types'

type LayoutProps = {
    formComponent: React.ReactNode
    seoProps: SeoProps
    price: Price
    title: string
    slug?: string
}

const PlansLayout = ({ formComponent, seoProps, price, title }: LayoutProps) => {
    return (
        <SeoWrapper seoProps={seoProps}>
            <MaxWidthContainer centraliseChildren>
                <Box display="flex" flexDirection="column" height="100%" px={[0, 0, 0, 2, 2]}>
                    <CheckoutNavBar />
                    <Box
                        display={['unset', 'unset', 'unset', 'flex', 'flex']}
                        paddingBottom={[0, 0, 0, 4, 4]}
                        flexDirection="row"
                        flex={1}
                    >
                        <Box height="100%" bg="neutral.0" flex={1}>
                            <Box p={2}>
                                <Title>{title}</Title>

                                {formComponent}
                            </Box>
                        </Box>
                        {/* Large screens - display as right column */}
                        <Hide on={[true, true, true, false, false]} flex={1}>
                            <Box height="100%" bg="neutral.50" flex={1}>
                                <CheckoutDetail price={price} />
                            </Box>
                        </Hide>
                    </Box>

                    {/* Small screens - display sticky  */}
                    <Hide on={[false, false, false, true, true]}>
                        <FullWidthBackground backgroundColor="neutral.50" sticky>
                            <CheckoutDetail price={price} />
                        </FullWidthBackground>
                    </Hide>
                </Box>
            </MaxWidthContainer>
        </SeoWrapper>
    )
}

const Title = styled.div<ThemedComponent<{}>>`
    text-transform: uppercase;
    font-style: italic;
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    padding-bottom: 1rem;
`

export default PlansLayout
